.verify-dt .react-datepicker-wrapper {
  width: 100%;
}
.video-upload .file-upload-input {
  position: relative;
}
.video-upload .loader {
  display: flex;
  position: absolute;
  top: 50%;
  height: 38.6px;
  background-color: rgba(0, 0, 0, 0.4);
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}
.video-upload .loader.hide {
  display: none;
}
.spinner-border {
  width: 1rem;
  height: 1rem;
  font-size: 12px;
}
.confirm-button {
  min-width: 100px;
}
.cursor-pointer {
  cursor: pointer;
}
@media only screen and (min-width: 576px) {
  .video-verify-modal {
    max-width: 90%;
  }
}

.otpContainer {
  margin: 5% auto;
  text-align: center;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* Chrome, Safari, Edge, Opera */
.otpInput::-webkit-outer-spin-button,
.otpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otpInput[type="number"] {
  -moz-appearance: textfield;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 375px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}
